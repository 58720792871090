







import { Component, Vue } from "vue-property-decorator";
import { buttonOptions } from "@/components/buttons/buttonOptions";
import { RouterNames } from "@/router/routernames";
import { UtilsString } from "@/utils/utils-string";
@Component({
  components: {
    Opciones: () => import("@/components/buttons/opciones.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class handler_ficha_inventario extends Vue {
  public get buttons() {
    var btn: buttonOptions[] = [];
    let is_sub_inventario = UtilsString.IsValidParamUrl(
      this.$route.query.sub_inventario
    );

    btn.push(
      new buttonOptions({
        id: 1,
        title: is_sub_inventario ? "Sub inventario" : "Inventario",
        descripcion: is_sub_inventario
          ? "Formulario de sub inventario"
          : "Formulario de inventario",
        routername: RouterNames.inventario_formulario,
        icono: "fad fa-inventory",
        EventMethod: this.inventario_formulario,
        permiso: "read_inventario",
      })
    );

    if (
      Number.parseInt(this.$route.params.id.toString()) > -1 &&
      !is_sub_inventario
    ) {
      btn.push(
        new buttonOptions({
          id: 2,
          title: "Tareas",
          descripcion: "Tareas vinculadas al inventario",
          routername: RouterNames.inventario_tareas,
          icono: "fad fa-ballot-check",
          EventMethod: this.inventario_tareas,
          permiso: "read_tareas",
        })
      );
      btn.push(
        new buttonOptions({
          id: 3,
          title: "Incidencias",
          descripcion: "Incidencias vinculadas al inventario",
          routername: RouterNames.inventario_incidencias,
          icono: "fad fa-ticket-alt",
          EventMethod: this.inventario_incidencias,
          permiso: "read_incidencias",
        })
      );
      btn.push(
        new buttonOptions({
          id: 4,
          title: "Subinventario",
          descripcion: "Piezas sustituidas del inventario",
          routername: RouterNames.subinventarios,
          icono: "fad fa-starfighter-alt",
          EventMethod: this.inventario_piezas,
          permiso: "read_incidencias",
        })
      );
      btn.push(
        new buttonOptions({
          id: 4,
          title: "Mediciones",
          descripcion: "Mediciones técnicas y comprobaciones del inventario",
          routername: RouterNames.mediciones_tecnicas_inventario,
          icono: "fad fa-thermometer-quarter",
          EventMethod: this.inventario_medicion,
          permiso: "read_inventario",
        })
      );
    }
    return btn;
  }

  public inventario_formulario() {
    this.$router.push({
      name: RouterNames.inventario_formulario,
      params: { id: this.$route.params.id },
    });
  }

  public inventario_tareas() {
    this.$router.push({
      name: RouterNames.inventario_tareas,
      params: { id: this.$route.params.id },
    });
  }

  public inventario_piezas() {
    this.$router.push({
      name: RouterNames.subinventarios,
      params: { id: this.$route.params.id },
    });
  }

  public inventario_medicion() {
    this.$router.push({
      name: RouterNames.mediciones_tecnicas_inventario,
      params: { id: this.$route.params.id },
    });
  }

  public inventario_incidencias() {
    this.$router.push({
      name: RouterNames.inventario_incidencias,
      params: { id: this.$route.params.id },
    });
  }
}
