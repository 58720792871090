import { BaseDto } from "@/shared/dtos/base-dto";

export class buttonOptions extends BaseDto {
  public id!: number;
  public title!: string;
  public icono!: string;
  public descripcion!: string;
  public routername!: string;
  public EventMethod!: (...args: any[]) => void;
  public permiso: string = "";
  public permisos: string[] = [];
}
